/**
 * Site header
 */
.site-header {
  // Positioning context for the mobile navigation icon
  .wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: $header-height;
    padding: 0 $large-spacing;
  }
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  white-space: nowrap;
  //float: left;

  &,
  &:visited {
    color: $nav-color;
  }
  &:hover,
  &:focus {
    color: $brand-light;
  }
}

$desktop-nav-hover-grow: 8px;
.site-nav {
  margin-left: auto;
  line-height: $base-line-height * $base-font-size * 2.25;
  flex-shrink: 0;
  padding-left: $large-spacing;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    li {
      display: inline-block;
      position: relative;
      .page-link {
        color: $nav-color;
        line-height: $base-line-height;
        text-decoration: none;
        margin-left: 10px;
        &:hover,
        &:focus {
          text-decoration: none;
          color: $brand-light;
        }
        &.active {
          color: $brand-light;
        }
      }
      // Gaps between nav items, but not on the last one
      &:not(:last-child) .page-link {
        margin-right: 10px;
      }
      @include media-query-inverse($width-hamburger) {
        .page-link {
          &.in-path {
            color: $brand-light;
          }
        }
        ul.submenu {
          display: none;
          background: $dropdown-menu-background-color;
          li {
            display: block;
            padding: 0;
            line-height: 1;
            border-top: solid 1px $dropdown-menu-divider-color;
            .page-link {
              display: block;
              padding: $default-spacing;
              margin: 0;
            }
          }
        }
        &.has-subpages:hover {
          background-color: $dropdown-menu-background-color;
          margin-left: -$desktop-nav-hover-grow;
          margin-right: -$desktop-nav-hover-grow;
          &>.page-link {
            padding-left: $desktop-nav-hover-grow;
            padding-right: $desktop-nav-hover-grow;
          }
        }

        &:hover ul.submenu,
        ul.submenu:hover {
          display: block;
          position: absolute;
          z-index: 1000;
        }
      }
    }
  }

  @include media-query($width-hamburger) {
    label[for="nav-trigger"] {
      display: block;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $color-white;
        fill-opacity: 0.8;
        &:hover,
        &:focus {
          fill-opacity: 1;
        }

      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      position: absolute;
      top: $header-height;;
      right: 0;
      left: 0;
      width: 100vw;
      text-align: center;
      background-color: $hamburger-menu-background-color;
      z-index: 100;
    }

    .page-link {
      display: block;
      padding: $default-spacing 0;
      border-top: solid 1px $dropdown-menu-divider-color;
      &:not(:last-child) {
        margin-right: 0;
        margin-left: 0;
      }
    }

    ul {
      li {
        display: block;
        .page-link {
          display: block;
          padding: $default-spacing 0;
          border-top: solid 1px $dropdown-menu-divider-color;
          margin-left: 0;
        }
        &:not(:last-child) .page-link {
          margin-right: 0;
        }
        ul.submenu {
          display: block;
          li {
            font-size: 0.95em;
            display: block;
          }
        }
      }
    }

  }
}


/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(33.333% - (#{$spacing-unit} / 2));
  width:         calc(33.333% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(33.333% - (#{$spacing-unit} / 2));
  width:         calc(33.333% - (#{$spacing-unit} / 2));
  text-align: center;
}

.footer-col-3 {
  width: -webkit-calc(33.333% - (#{$spacing-unit} / 2));
  width:         calc(33.333% - (#{$spacing-unit} / 2));
  table {
    margin-left: auto;
  }
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}
