$color-dark-purple: #05032D;
$color-peach: #E5A37B;
$color-green: #BAAE57;
$color-white: #FFFFFF;
$color-dark: #000000;
$color-light-grey: #CCCCCC;
$color-red: #b0122c;
$color-green: #3F5739;
$color-dark-green: #384136;
$very-dark-grey: #151215;
$very-dark-brown: #3F341E;
$color-light-brown: #BFB49E;

$text-color: #111111;
$brand-light: $color-light-brown;
$brand-dark: $color-dark-purple;
$brand-color: $color-green;
$divider-color: $color-dark-green;

$nav-color: $color-white;
$header-background-color: $color-green;
$hamburger-menu-background-color: rgba(0,0,0,.93);
$dropdown-menu-background-color: rgba(0,0,0,.93);
$dropdown-menu-divider-color: $color-dark-green;
$footer-color: $color-white;
$footer-background-color: $very-dark-brown;
$footer-copyright-notice-background-color: $brand-light;
$footer-copyright-notice-color: $color-dark;

$small-spacing: 5px;
$medium-spacing: 10px;
$default-spacing: 15px;
$large-spacing: 30px;
$extra-large-spacing: 60px;

$header-height: 100px;
$footer-height: 100px;

$base-font-family: 'Source Sans Pro', sans-serif;
$content-width: 800px;
$on-laptop: 800px;

$width-hamburger: $on-laptop;