.site-header {
    background-color: $header-background-color;
    @include media-query($width-hamburger) {
        &.nav-open {
            background-color: darken($header-background-color, 20%);
        }
    }
}

.site-footer {
    background-color: $footer-background-color;
    color: $footer-color;
    .footer-col-wrapper,
    a {
        color: inherit;
    }
}

$gutter-width: 20px;
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -1 * $gutter-width / 2;
    margin-left: -1 * $gutter-width / 2;

    .col-1 { flex-basis: 8.333334%; }
    .col-2 { flex-basis: 16.666666%; }
    .col-3 { flex-basis: 25%; }
    .col-4 { flex-basis: 33.333334%; }
    .col-5 { flex-basis: 41.666666%; }
    .col-6 { flex-basis: 50%; }
    .col-7 { flex-basis: 58.3333334%; }
    .col-8 { flex-basis: 66.6666666%; }
    .col-9 { flex-basis: 75%; }
    .col-10 { flex-basis: 83.33333334%; }
    .col-11 { flex-basis: 91.6666666%; }
    .col-12 { flex-basis: 100%; }

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: $gutter-width / 2;
        padding-left: $gutter-width / 2;
        box-sizing: border-box;
        img {
            max-width: 100%;
        }
        @include media-query($on-laptop) {
            flex-basis: 100%;
        }
    }
}

